import { Link } from "react-router-dom";

const Media = () => {
    return (
        <>
            <div className="mediatitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>News 2024</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Media</Link>
                                    </li>
                                    <li>
                                        <Link to="/Media">News</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container media-sec mt-20">
                <div className="row no-gutters">
                    <div className="col-md-5">&nbsp;</div>
                    <div className="col-md-2 text-center">
                        <Link to="/Media2023" className="text-center">
                            <b>ARCHIVE 2023</b>
                        </Link>
                    </div>
                    <div className="col-md-5">&nbsp;</div>
                </div>
                <div className="row no-gutters">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <ul style={{ borderTop: "1px solid #ccc" }} className="list-group ">
                            {/* //20-11-2024 update */}
                            <a
                                href="/download/news/2023-2024/Construction Times, Oct 2024-Smart Solution for a Thirsty Nation.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Smart Solutions for a Thirsty Nation: The Future of Water
                                        Management
                                    </h5>
                                    <small>Construction Times, October 2024</small>
                                </li>
                            </a>{" "}
                            <a
                                href="/download/news/2023-2024/Construction Times, Oct 2024-Cover Story_Recycling is the Future.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Recycling is the Future</h5>
                                    <small>Construction Times, October 2024</small>
                                </li>
                            </a>{" "}
                            <a
                                href="/download/news/2023-2024/B2B Purchase, 17th Ann. Oct 2024-Smart Solution for Sustainable Water Management.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Smart Solution for Sustainable Water Management</h5>
                                    <small>B2B Purchase, October 2024</small>
                                </li>
                            </a>
                            <li className="list-group-item ">
                                <h5 className="font-bold">
                                    iPUMPNET Unveiled by the Union Minister of Jal Shakti
                                </h5>
                                <div className="newsList flex flex-col gap-2 justify-between text-black">
                                    <a
                                        href="/download/news/2023-2024/World Pumps, 26 Sep 2024-iPumpnet unveiled at India Water Week.pdf"
                                        target="_blank"
                                    >
                                        <small>World Pumps,</small>
                                    </a>
                                    <a
                                        href="/download/news/2023-2024/Water Digest, 22 Sep 2024-iPUMPNET Unveiled by the Union Minister of Jal Shakti & Wins ET Infra Focus Award 2024 for Best Brand in Water Technology.pdf"
                                        target="_blank"
                                    >
                                        <small>Water Digest,</small>
                                    </a>
                                    <a
                                        href="/download/news/2023-2024/Water & Wastewater Asia, 24 Sep 2024_iPUMPNET Wins Economic Times Infra Focus Award 2024 for Best Brand in Water Technology.pdf"
                                        target="_blank"
                                    >
                                        <small>Water & Wastewater Asia,</small>
                                    </a>
                                    <a
                                        href="/download/news/2023-2024/H2O Global News, iPUMPNET Unveiled by the Union Minister of Jal Shakti & Wins ET Infra Focus Award 2024.pdf"
                                        target="_blank"
                                    >
                                        <small>H2O Global News,</small>
                                    </a>
                                    <a
                                        href="/download/news/2023-2024/Everything About Water, Sep 2024-iPUMPNET Unveiled by Jal Shakti Minister.pdf"
                                        target="_blank"
                                    >
                                        <small>Everything About Water,</small>
                                    </a>
                                    <a
                                        href="/download/news/2023-2024/Construction Insights, 26 Sep, 2024-Union Minister of Jal Shakti Unveiled iPUMPNET designed by Pump Academy.pdf"
                                        target="_blank"
                                    >
                                        <small>Construction Insights, </small>
                                    </a>
                                    <a
                                        href="/download/news/2023-2024/B2B Purchase, 26 Sep 2024-Pump Academy Launches iPUMPNET to Enhance Water Management Efficiency.pdf"
                                        target="_blank"
                                    >
                                        <small>
                                            B2B Purchase,
                                        </small>
                                    </a>
                                    <a
                                        href="/download/news/2023-2024/Africa Mining and Engineering News, 2 Oct 2024_iPumpnet unveiled at India Water Week.pdf"
                                        target="_blank"
                                    >
                                        <small>
                                            Africa Mining and Engineering News,
                                        </small>
                                    </a>{" "}
                                    <a
                                        href="/download/news/2023-2024/OEM Update, 21 Sep 2024-Pump Academy introduces iPUMPNET at the India Water Week expo.pdf"
                                        target="_blank"
                                    >
                                        <small>
                                            OEM Update,
                                        </small>
                                    </a>{" "}
                                    <a
                                        href="/download/news/2023-2024/Insights Success, 10 Oct 2024-iPUMPNET Unveiled by the Union Minister of Jal Shakti & Wins ET Infra Focus Award 2024 for Best Brand in Water Technology.pdf"
                                        target="_blank"
                                    >
                                        <small>
                                            Insights Success,
                                        </small>
                                    </a>{" "}
                                    <a
                                        href="/download/news/2023-2024/Affairs Cloud, 4 October 2024.pdf"
                                        target="_blank"
                                    >
                                        <small>Affairs Cloud, </small>
                                    </a>{" "}
                                </div>
                            </li>
                            {/* //23-09-2024 update */}
                            <a
                                href="/download/news/2023-2024/TimesTech Buzz, Sep 2024-Transitioning to Energy Efficient Pumping System.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Transitioning to Energy Efficient Pumping Systems</h5>
                                    <small>Times Tech Buzz, September 2024</small>
                                </li>
                            </a>
                            {/* //17-09-2024 update */}
                            <a
                                href="/download/news/2023-2024/Everything About Water, July 2024-Budget Review_Anil Sethi.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Insights on Union Budget 2024</h5>
                                    <small>Everything About Water, July 2024</small>
                                </li>
                            </a>
                            {/* //31-07-2024 update */}
                            <a
                                href="/download/news/2023-2024/Construction Insights, 23 July 2024-Union Budget 2024_ Implementing Technology for Economic Growth.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Implementing Technology for Economic Growth</h5>
                                    <small>Construction Insights, 23 July 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Everything About Water, June 2024-Interview with Anil Sethi.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Interview with Anil Sethi, Chairman, Pump Academy</h5>
                                    <small>
                                        Everything About Water, 24th Anniversary-June 2024
                                    </small>
                                </li>
                            </a>
                            {/* //12-06-2024 update */}
                            <a
                                href="/download/news/2023-2024/Agriculture World, June 2024-Revolutionizing Growth & Sustainability in Agriculture.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Revolutionizing Growth & Sustainability in Agriculture
                                    </h5>
                                    <small>Agriculture World, June 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Everything About Water, May 2024-Asset Management_Key to a Better Water Future.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Asset Management: Key to a Better Water Future</h5>
                                    <small>Everything About Water, May 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Construction Insights, 23 May 2024-Pump Academy supporting BWSSB in Optimizing Pumping Stations in Bengaluru, India.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Pump Academy Supporting BWSSB in Optimizing Pumping Stations
                                        in Bengaluru, India
                                    </h5>
                                    <small>Construction Insights, 23 May 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Water & Wastewater Asia, 20 May 2024-Pump Academy secures pumping station optimisation project in Bengaluru.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Pump Academy Secures Pumping Station Optimisation Project in
                                        Bengaluru
                                    </h5>
                                    <small>Water & Wastewater Asia, 20 May 2024</small>
                                </li>
                            </a>
                            {/* //16-05-2024 update */}
                            <a
                                href="/download/news/2023-2024/OEM Update, May 2024-Modernise Wastewater System for Sustainable Manufacturing.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Revolutionizing Growth & Sustainability in Agriculture
                                    </h5>
                                    <small>OEM Update, May 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/OEM Update, May 2024-Optimal Wastewater Management in Manufacturing.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Optimal Wastewater Management in Manufacturing</h5>
                                    <small>OEM Update, May 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/World Pumps-India’s Pump Academy wins pumping station optimization project in Bengaluru [13.5.24].pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        India’s Pump Academy wins pumping station optimization
                                        project in Bengaluru
                                    </h5>
                                    <small>World Pumps Magazine, 13 May 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Construction World-Pump Academy secures BWSSB project for optimisation in Bengaluru [13.5.24].pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Pump Academy secures BWSSB project for optimization in
                                        Bengaluru
                                    </h5>
                                    <small> Construction World Magazine, 13 May 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Everything About Water-Pump Academy Secures INR 80 Million Project for Pumping System Optimization in Bengaluru [13 May 2024].pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Pump Academy Secures INR 80 Million Project for Pumping
                                        System Optimization in Bengaluru
                                    </h5>
                                    <small>Everything About Water, 13 May 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Insights Success, April 2024-Anil Sethi-Entreprenuer of the Year 2024.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Anil Sethi: The Dynamic Catalyst of Indian Infrastructure
                                        Development
                                    </h5>
                                    <small>Insights Success Magazine, April 2024</small>
                                </li>
                            </a>
                            {/* ********* */}
                            <a
                                href="/download/news/2023-2024/B2B Purchase Magazine, April 2024-Flowing Forward to a Cleaner Future.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Flowing forward to a Cleaner Future</h5>
                                    <small>B2B Purchase, April 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Everything About Water, Mar 2024-World Water Day Message_Anil Sethi.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>World Water Day Message by Chairman, Pump Academy</h5>
                                    <small>Everything About Water, March 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/ENTERPRISE Review, March 2024-Profile_Anil Sethi.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Profile: Mr. Anil Sethi, Chairman, Pump Academy</h5>
                                    <small>Enterprise Review, March 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Construction Insights_Mar, 2024_World Water Day 2024_ Water-For Peace & Prosperity.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Water for Peace & Prosperity: World Water Day 2024</h5>
                                    <small>Construction Insights, March, 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Construction-Times-Mar-2024-Water-Sustainability-Anil-Sethi.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Water Sustainability: Navigating Challenges for a Resilient
                                        Future
                                    </h5>
                                    <small>Construction Times, March 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Construction-Times-Mar-2024-Cover-Story-Water-Future-Anil-Sethi.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Cover Story - Water Future: Addressing India's Water Needs
                                    </h5>
                                    <small>Construction Times, March 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Everything-About-Water-Online-Feb-2024-Budget-2024-Implementing-Vision-Development.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Budget 2024: Implementing Vision for Development</h5>
                                    <small>Everything About Water Online, 14 Feb 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Construction-Insights-2024-Medium-High-Energy-Consumption-Pumping-System.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>High Energy Consumption in Pumping System</h5>
                                    <small>Construction Insights, 9 Feb, 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Water-Digest-February-2024-Revolutionizing-Water-Pumping-System.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Revolutionizing Water Pumping System with Digital Solutions
                                    </h5>
                                    <small>Water Digest, February 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Water-Age-Magazine-Jan-2024-Budget-2024-Implementing-Vision-for-Development.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Budget 2024 - Implementing Vision for Development</h5>
                                    <small>Water Age Magazine, January 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Construction-Insights-Jan-2024-Budget-2024-Emphasis-Enhancing-Energy-Efficiency-Reducing-Carbon-Emissions.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Budget 2024: Emphasis should be on Enhancing Energy
                                        Efficiency and Reducing Carbon Emissions
                                    </h5>
                                    <small>Construction Insights, 29 Jan, 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Everything-About-Water-Online-Jan-2024-iPUMPNET-Unveiled-IT-BT-Minister-Karnataka.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>iPUMPNE: Unveiled by IT & BT Minister of Karnataka</h5>
                                    <small>Everything About Water Online, 24 January 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Construction-Insights-Medium-Jan-2024-Pumping-Station-Proactive-Maintenance-Enhanced-Reliability-Efficiency"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>
                                        Pumping Station: Proactive Maintenance for Enhanced
                                        Reliability and Efficiency
                                    </h5>
                                    <small>Construction Insights, 22 January 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Everything-About-Water-Jan-2024-Pumping-Station-Crucial-Water-Supply.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Pumping Station: Crucial for Water Supply</h5>
                                    <small>Everything About Water, January 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Everything-About-Water-Jan-2024-Budget-2024-Review-Anil-Sethi.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Budget 2024: Review by Anil Sethi</h5>
                                    <small>Everything About Water, January 2024</small>
                                </li>
                            </a>
                            <a
                                href="/download/news/2023-2024/Construction Times-Infrastructure-Development-Shaping-Future.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item">
                                    <h5>Infrastructure Development: Shaping Future</h5>
                                    <small>Construction Times, January 2024</small>
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Media;
